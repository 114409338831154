import React, { useEffect, useRef, memo, useMemo, useLayoutEffect } from "react"
import waterfall from "../../helpers/waterfall"
import "./GridGallery.scss"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import photoswipe from "photoswipe"
import "photoswipe/style.css"
const GridGallery = ({
  gapRow = 0,
  gapCol = 0,
  column = 4,
  images
}) => {
  const gridRef = useRef(null)
  const saveColumn = useRef()
  saveColumn.current = column
  const galleryID = useMemo(() => {
    return `gallery-${Math.random()
      .toString(36)
      .substr(2, 9)}`
  }, [])
  useEffect(() => {
    let gridRefCurrent = gridRef.current
    const handleGrid = () => {
      waterfall(gridRefCurrent, saveColumn.current)
    }

    const resize = new ResizeObserver(() => {
      handleGrid()
    })

    resize.observe(gridRefCurrent)
    return () => {
      resize.unobserve(gridRefCurrent)
    }
  }, [])

  images = images.reverse();

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + galleryID,
      children: "a",
      pswpModule: () => photoswipe
    })
    lightbox.init()

    return () => {
      lightbox.destroy()
      lightbox = null
    }
  }, [])

  useLayoutEffect(() => {
    waterfall(gridRef.current, saveColumn.current)
  }, [images])

  return (
    <div
      ref={gridRef}
      id={galleryID}
    >
      {images.length > 0 && images.map((img, index) => (
        <a
          href={img.image.sourceUrl}
          data-pswp-width={img.image.width}
          data-pswp-height={img.image.height}
          key={index}
          style={{
            padding: `${gapRow}px ${gapCol}px`,
            display: "block",
            width: `${10 / column * 10}%`
          }}
        >
          <div
            style={{
              paddingTop: `${img.image.height / img.image.width * 100}%`,
              position: "relative"
            }}
          >
            <img
              src={img.image.sourceUrl}
              alt=""
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: "100%"
              }}
            />
          </div>
        </a>
      ))}
    </div>
  )
}

export default memo(GridGallery)