export default function waterfall(container, column = 3) {
  if (typeof (container) === "string") {
    // eslint-disable-next-line no-param-reassign
    container = document.querySelector(container)
  }

  function style(el) {
    return window.getComputedStyle(el)
  }

  function margin(name, el) {
    return parseFloat(style(el)["margin" + name]) || 0
  }

  function px(n) {
    return n + "px"
  }

  function y(el) {
    return parseFloat(el.style.top)
  }

  function x(el) {
    return parseFloat(el.style.left)
  }

  function width(el) {
    return parseFloat(style(el).width)
  }

  function height(el) {
    return parseFloat(style(el).height)
  }

  function bottom(el) {
    return y(el) + height(el) + margin("Bottom", el)
  }

  function right(el) {
    return x(el) + width(el) + margin("Right", el)
  }

  function sort(l) {
    // eslint-disable-next-line no-param-reassign
    l = l.sort(function (a, b) {
      let bottom_diff = bottom(b) - bottom(a)
      return bottom_diff || x(b) - x(a)
    })
  }

  let boundary = {
    els: [],
    add: function (el) {
      this.els.push(el)
      sort(this.els)
      this.els = this.els.slice(0, column)
    },
    min: function () {
      return this.els[this.els.length - 1]
    },
    max: function () {
      return this.els[0]
    }
  }

  function placeEl(el, top, left) {
    el.style.position = "absolute"
    el.style.top = top
    el.style.left = left
    boundary.add(el)
  }

  function placeFirstElement(el) {
    placeEl(el, "0px", px(margin("Left", el)))
  }

  function placeAtTheFirstLine(prev, el) {
    placeEl(el, prev.style.top, px(right(prev) + margin("Left", el)))
  }

  function placeAtTheSmallestColumn(minEl, el) {
    placeEl(el, px(bottom(minEl) + margin("Top", el)), px(x(minEl)))
  }

  function adjustContainer(container, maxEl) {
    container.style.position = "relative"
    container.style.height = px(bottom(maxEl) + margin("Bottom", maxEl))
  }

  function thereIsSpace(els, i) {
    return right(els[i - 1]) + width(els[i]) <= width(container)
  }

  let els = container.children

  if (els.length) {
    placeFirstElement(els[0])
  }

  let i = 1
  for (; i < els.length && thereIsSpace(els, i); i++) {
    placeAtTheFirstLine(els[i - 1], els[i])
  }

  for (; i < els.length; i++) {
    placeAtTheSmallestColumn(boundary.min(), els[i])
  }

  adjustContainer(container, boundary.max())
}