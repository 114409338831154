import React, { useEffect, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import GridGallery from "../../components/GridGallery"
import "./gallery.scss"
import Seo from "../../components/seo"

const gallery = ({ data }) => {
  const gallery = data.allWpGalleryCategory.edges.map(gal => {
    const galObject = {
      id: gal.node.id,
      name: gal.node.name,
      galleries: gal.node.galleries
    }

    return galObject
  })
  const seo = {
    title:"Gallery - Backa Australia",
    description:"View our gallery to see a range of photos featuring the Backa fleet, production facility, retail and market displays and of course, salamis!"
  }
  const lists = gallery.map(gal => gal.galleries.nodes)
  const allImages = lists.flat(1)
  
  const initalGallery = 'all-images'
  const [isMobile, setIsMobile] = useState(false)
  const [filter, setFilter] = useState(initalGallery)
  const list = gallery.filter(gal => gal.id === filter)
  const images = filter === initalGallery ? allImages : list[0].galleries.nodes

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Layout
      pageClass="page-gallery"
    >
      <Seo title={seo.title} description={seo.description}/>
      <div className="filter-gallery">
        <ul>
          <li>
            <button
              onClick={() => setFilter(initalGallery)}
              className={`${filter === initalGallery ? "active" : ""}`}
            >All</button>
          </li>
          {
            gallery.reverse().map(gal => {
              return (
                <li>
                  <button onClick={() => setFilter(gal.id)}
                    className={`${filter === gal.id ? "active" : ""}`}
                  >
                    {gal.name}
                  </button>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div className="container">
        {images.length > 0 && <GridGallery
          gapRow={4}
          gapCol={3.5}
          column={isMobile ? 2 : 4}
          images={images}
        />}
      </div>
    </Layout>
  )
}

export default gallery

export const BASE_INFORM_GALLERY = graphql`
  query getGalleries {
    allWpGalleryCategory {
      edges {
        node {
          id
          name
          ...GalleryDetails
        }
      }
    }
  }
`

export const FRAGMENT_GALLERY = graphql`
  fragment GalleryDetails on WpGalleryCategory {
    galleries {
      nodes {
        name
        image {
          sourceUrl
          width
          height
          mediaDetails {
            width
            height
            sizes {
              sourceUrl
              width
              height
            }
          }
        }
      }
    }
  }
`

